import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Door } from '../models/door.model';
import { DoorExtra } from '../models/door-extra.model';
import { AvailableDoorExtra } from '../models/available-door-extra.model';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DoorService {

  constructor(
    private http: HttpClient
  ) { }

  create(door) {
    return this.http.post<Door>(`${environment.base_url}v1/door`, door);
  }

  getDoorTypes(){
    return this.http.get(`${environment.base_url}v1/door/type`);
  }

  update(id, door) {
    return this.http.put<Door>(`${environment.base_url}v1/door/${id}`, door);
  }

  delete(id) {
    return this.http.delete<Door>(`${environment.base_url}v1/door/${id}`);
  }

  getById(id) {
    return this.http.get<Door>(`${environment.base_url}v1/door/${id}`);
  }

  postFile(image){
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(`${environment.base_url}v1/door/image/upload`, formData);
  }

  getProjectDoorTypes(queryParams?){
    return this.http.get(`${environment.base_url}v1/door/use/type`,{params:queryParams});
  }

  getAll(queryParams?) {
    return this.http.get(`${environment.base_url}v1/door`,{params:queryParams})
      .pipe(
        map(responseData => {
          //console.log(responseData);
          const postsArray: Door[] = [];

          responseData['data']['data'].forEach((e) => {
            postsArray.push(new Door(e));
          })

          return postsArray;

        }))
  }

  getAllOther(queryParams?) {
    return this.http.get(`${environment.base_url}v1/door`,{params:queryParams});
  }

  copyExtras(id,numbers){
    return this.http.put(`${environment.base_url}v1/door/`+id+`/extras`,{extras:numbers})
  }

  getAllExtras() {
    return this.http.get(`${environment.base_url}v1/door/extra/list`)
      .pipe(
        map(responseData => {
          //console.log(responseData);
          const postsArray: AvailableDoorExtra[] = [];

          responseData['data'].forEach((e) => {
            postsArray.push(new AvailableDoorExtra(e));
          })

          return postsArray;

        }))
  }


}
