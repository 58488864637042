import { Supplier } from "./supplier.model";

export class DoorExtra {
    id : number;
    title : string;
    cost_price : number;
    sale_price : number;
    type:{
        id: number;
        title:string;
        allow_multiple: boolean;
        is_per_door: boolean;
        is_required: boolean;  
      };
    category: {
        id: string;
        title: string;
    };
    should_use_door_supplier:boolean;
    supplier: Supplier;
    is_cost_percentage:boolean;
    is_sale_percentage:boolean;
    image_url:string;
    code:string;


    constructor(input?) {
        input = input || {};
        this.id = input.id || null;
        this.title = input.title || '';
        this.cost_price = input.cost_price || 0;
        this.sale_price = input.sale_price || 0;
        this.category = input.category || {};
        this.type = input.type || {};
        this.supplier = input.supplier || {};
        this.is_cost_percentage = input.is_cost_percentage || false;
        this.is_sale_percentage = input.is_sale_percentage || false;
        this.image_url = input.image_url || '';
        this.code = input.code || '';
        this.should_use_door_supplier = input.should_use_door_supplier || false;
    }
}